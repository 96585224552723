import React from "react";
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

import App from "./App";
import LandingPage from "./components/LandingPage/LandingPage";
import Logout from "./components/UserManagement/Logout/Logout";
import BDAutomatedChecks from "./components/BDAutomatedChecks/BDAutomatedChecks";
import Home from "./components/Home/Home";
import ReportingResults from "./components/Home/ReportingResults/ReportingResults";
import GuestUserChecks from "./components/GuestUserChecks/GuestUserChecks";
import BizDevBD from "./components/BizDevBD/BizDevBD";

const Routes = (props) => {
  let routes = "";
  if (props.isAuthenticated || props.isAuthData) {
    routes = (
      <Switch>
        <Route
          exact
          path="/report"
          render={({ match }) => <Home match={match} />}
        />
        <Route
          exact
          path="/reporting"
          render={({ match }) => <ReportingResults match={match} />}
        />
        <Route exact path="/logout" component={Logout} />
        <Route path="/sign-in" component={LandingPage} />
        <Route path="/sign-up" component={LandingPage} />
        <Route path="/reset-request" component={LandingPage} />
        <Route path="/reset-password" component={LandingPage} />
        <Route path="/automated-report" component={BDAutomatedChecks} />

        <Route path="/bd-report" component={BizDevBD} />
        <Route path="/" component={App} />
        <Redirect to="/sign-in" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route
          exact
          path="/report"
          render={({ match }) => <Home match={match} />}
        />
        <Route
          exact
          path="/reporting"
          render={({ match }) => <ReportingResults match={match} />}
        />
        <Route path="/sign-in" component={LandingPage} />
        <Route path="/sign-up" component={LandingPage} />
        <Route path="/reset-request" component={LandingPage} />
        <Route path="/reset-password" component={LandingPage} />
        <Route path="/automated-report" component={BDAutomatedChecks} />

        <Route path="/bd-report" component={BizDevBD} />

        <Route path="/aware-report" component={GuestUserChecks} />
        <Redirect to="/sign-in" />
      </Switch>
    );
  }

  return <Router>{routes}</Router>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});

export default withRouter(connect(mapStateToProps, null)(Routes));

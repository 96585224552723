import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Upload, Select, Layout, Menu } from "antd";
import Notification from "../CommonComponents/Notification/index";
import config from "../../config";
import axios from "axios";
import "./bd.less";
import { withRouter } from "react-router";
import { UploadOutlined } from "@ant-design/icons";
import logo from "../../assets/Asset3.png";
import awareLogo from "../../assets/Aware-350-square.png";

const { Header, Footer } = Layout;

//lead generation tool
const BDAutomatedChecks = (props) => {
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();

  /* 
  takes email from the the url
  */
  useEffect(() => {
    sessionStorage.removeItem("domain");
    sessionStorage.removeItem("imgData");
    let domain = "";
    if (props.location && props.location.search) {
      domain = props.location.search;
      domain = domain.replace("?domain=", "");
    }

    form.setFieldsValue({
      domain: domain,
    });
  }, []);

  /* 
  called on submit to make an api call to businessdev
  */
  const onFinish = async (values) => {
    var data = new FormData();
    data.append("domain", values.domain);
    data.append("country", values.country);
    data.append("source", "requests");

    sessionStorage.setItem("domain", values.domain);
    setloading(true);

    let options = {
      method: "post",
      url: `${config.adminApi}job/execute/businessdev`,
      data: data,
    };
    axios(options)
      .then(function (response) {
        props.history.push({
          pathname: "/aware-report",
          state: { results: response.data.results },
        });
      })
      .catch(function (error) {
        console.log(error);
        setloading(false);

        Notification("error", "Something went wrong");
      });
  };

  // default ant d function to upload image 
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  //  saves logi to session
  const onChangeLogo = async (file) => {
    let imgData = await getBase64(file.file.originFileObj);
    sessionStorage.setItem("imgData", imgData);
  };

  // converts image to base 64
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <>
      <Layout className="sss-header">
        <Header
          style={{
            position: "fixed",
            zIndex: 10,
            width: "100%",
            background: "white",
            height: 65,
            fontFamily: "vagRund",
            boxShadow: "5px rgba(0,0,0,0.7)",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Menu theme="light" mode="horizontal">
                <a href="https://www.citymaas.io/">
                  {" "}
                  <img
                    src={logo}
                    height={40}
                    alt="logo"
                    style={{ marginTop: -5 }}
                  />
                </a>
              </Menu>
            </div>
            <div></div>
          </div>
        </Header>
        <div className="bg-bd-all">
          <div className="aware-div">
            <h1>Customise your accessibility report</h1>
            <img src={awareLogo} className="aware-img" />
          </div>

          <div className="det-aware">
            <h5>
              Tell us which country you are in for local guidance and optionally
              upload a logo of your business
            </h5>
          </div>

          <Card className="bd-card">
            <Form
              name="auto-checks"
              onFinish={onFinish}
              autoComplete="on"
              form={form}
            >
              {" "}
              <Form.Item
                name="domain"
                label="Domain"
                rules={[
                  {
                    message:
                      "Please add your domain as https://www.example.com",
                    pattern: new RegExp(
                      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
                    ),
                  },
                  {
                    required: true,
                    message: "Please input yourdomain!",
                  },
                ]}
              >
                <Input placeholder="https://" />
              </Form.Item>
              <Form.Item name="country" label="Select a country" required>
                <Select placeholder="Select a country">
                  <Select.Option value="Australia">Australia</Select.Option>
                  <Select.Option value="Canada">Canada</Select.Option>
                  <Select.Option value="China">China</Select.Option>
                  <Select.Option value="European Union">
                    European Union
                  </Select.Option>
                  <Select.Option value="Hong Kong">Hong Kong</Select.Option>
                  <Select.Option value="India">India</Select.Option>
                  <Select.Option value="Israel">Israel</Select.Option>
                  <Select.Option value="Japan">Japan</Select.Option>
                  <Select.Option value="New Zealand">New Zealand</Select.Option>
                  <Select.Option value="Norway">Norway</Select.Option>
                  <Select.Option value="Republic of Korea">
                    Republic of Korea
                  </Select.Option>
                  <Select.Option value="Switzerland">Switzerland</Select.Option>
                  <Select.Option value="Taiwan">Taiwan</Select.Option>
                  <Select.Option value="United Kingdom">
                    United Kingdom
                  </Select.Option>
                  <Select.Option value="United States of America">
                    United States of America
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="file" label="Logo">
                <Upload
                  name="file"
                  listType="picture"
                  customRequest={dummyRequest}
                  onChange={onChangeLogo}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Layout>

      <Footer className="footer">
        <div className="footer-div">
          <p className="footer-text">
            ©CityMaaS Ltd 2021 |{" "}
            <a
              href="https://www.citymaas.io/privacy-policy"
              style={{ color: "#fff" }}
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </Footer>
    </>
  );
};

export default withRouter(BDAutomatedChecks);

module.exports = {
  //prod
  adminApi: "https://audit-check-api-server-production.azurewebsites.net/",
  reportApi:
    "https://assist-me-payments-backend-api-server-prod.azurewebsites.net/client/",

  //dev
  // reportApi:
  //   "https://assist-me-subscriptions-management-development.azurewebsites.net/client/",
  // adminApi: "https://audit-check-api-server-development.azurewebsites.net/",
};

import * as actionTypes from "./actionTypes";
import axios from "axios";
import config from "../../config";

/**
 *
 * {getPluginStatsStart} job execution process starts
 */
export const getPluginStatsStart = (data) => {
  return {
    type: actionTypes.GET_PLUGIN_STATS_START,
    data: data,
  };
};
/**
 *
 * {getPluginStatsSuccess} job execution completes succesfully
 */
export const getPluginStatsSuccess = (data) => {
  return {
    type: actionTypes.GET_PLUGIN_STATS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getPluginStatsFail} job execution completes fails
 */
export const getPluginStatsFail = (error) => {
  return {
    type: actionTypes.GET_PLUGIN_STATS_FAIL,
    error: error,
  };
};
/**
 *
 * {getPluginStats} job execution function call on button press
 */
export const getPluginStats = (obj, callback) => {
  return (dispatch) => {
    dispatch(getPluginStatsStart(true));
    axios
      .post(`${config.reportApi}plugin-stats`, obj)
      .then((response) => {
        dispatch(getPluginStatsStart(true));
        dispatch(
          getPluginStatsSuccess({ status: true, response: response.data })
        );
        dispatch(getPluginStatsStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getPluginStatsFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getPluginStatsStart(false));
      });
  };
};

/**
 *
 * {getAwareStatsStart} job execution process starts
 */
export const getAwareStatsStart = (data) => {
  return {
    type: actionTypes.GET_AWARE_STATS_START,
    data: data,
  };
};
/**
 *
 * {getAwareStatsSuccess} job execution completes succesfully
 */
export const getAwareStatsSuccess = (data) => {
  return {
    type: actionTypes.GET_AWARE_STATS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getAwareStatsFail} job execution completes fails
 */
export const getAwareStatsFail = (error) => {
  return {
    type: actionTypes.GET_AWARE_STATS_FAIL,
    error: error,
  };
};
/**
 *
 * {getAwareStats} job execution function call on button press
 */
export const getAwareStats = (obj, callback) => {
  return (dispatch) => {
    dispatch(getAwareStatsStart(true));
    axios
      .post(`${config.reportApi}aware-stats`, obj)
      .then((response) => {
        dispatch(getAwareStatsStart(true));
        dispatch(
          getAwareStatsSuccess({ status: true, response: response.data })
        );
        dispatch(getAwareStatsStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getAwareStatsFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getAwareStatsStart(false));
      });
  };
};

/**
 *
 * {getAwareReportsStart} job execution process starts
 */
export const getAwareReportsStart = (data) => {
  return {
    type: actionTypes.GET_AWARE_REPORTS_START,
    data: data,
  };
};
/**
 *
 * {getAwareReportsSuccess} job execution completes succesfully
 */
export const getAwareReportsSuccess = (data) => {
  return {
    type: actionTypes.GET_AWARE_REPORTS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getAwareReportsFail} job execution completes fails
 */
export const getAwareReportsFail = (error) => {
  return {
    type: actionTypes.GET_AWARE_REPORTS_FAIL,
    error: error,
  };
};
/**
 *
 * {getAwareReports} job execution function call on button press
 */
export const getAwareReports = (obj, callback) => {
  return (dispatch) => {
    dispatch(getAwareReportsStart(true));
    axios
      .post(`${config.reportApi}aware-report`, obj)
      .then((response) => {
        dispatch(getAwareReportsStart(true));
        dispatch(
          getAwareReportsSuccess({ status: true, response: response.data })
        );
        dispatch(getAwareReportsStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getAwareReportsFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getAwareReportsStart(false));
      });
  };
};

import  { combineReducers} from 'redux';
import authReducer from './auth';
import jobReducer from './jobs';
import analyticsReducer from './analytics';
import questionsReducer from './questions';



const rootReducer = combineReducers({
    authReducer,
    jobReducer,
    analyticsReducer,
    questionsReducer
})

export default rootReducer
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  setQuestionLoading: false,
  setQuestionSuccess: null,
  setQuestionError: false,

  setUserResponseLoading: false,
  setUserResponseSuccess: null,
  setUserResponseError: false,

  setFinishResponseLoading: false,
  setFinishResponseSuccess: null,
  setFinishResponseError: false,

  fetchReportLoading: false,
  fetchReportSuccess: null,
  fetchReportError: false,
};


const setQuestionStart = (state, action) => {
  return updateObject(state, {
    setQuestionError: false,
    setQuestionLoading: action.data,
  });
};

const setQuestionSuccess = (state, action) => {
  return updateObject(state, {
    setQuestionSuccess: action.data,
    setQuestionLoading: false,
  });
};

const setQuestionFail = (state, action) => {
  return updateObject(state, {
    setQuestionError: action.error,
    setQuestionLoading: false,
  });
};

const setUserResponseStart = (state, action) => {
  return updateObject(state, {
    setUserResponseError: false,
    setUserResponseLoading: action.data,
  });
};

const setUserResponseSuccess = (state, action) => {
  return updateObject(state, {
    setUserResponseSuccess: action.data,
    setUserResponseLoading: false,
  });
};

const setUserResponseFail = (state, action) => {
  return updateObject(state, {
    setUserResponseError: action.error,
    setUserResponseLoading: false,
  });
};

const setFinishResponseStart = (state, action) => {
  return updateObject(state, {
    setFinishResponseError: false,
    setFinishResponseLoading: action.data,
  });
};

const setFinishResponseSuccess = (state, action) => {
  return updateObject(state, {
    setFinishResponseSuccess: action.data,
    setFinishResponseLoading: false,
  });
};

const setFinishResponseFail = (state, action) => {
  return updateObject(state, {
    setFinishResponseError: action.error,
    setFinishResponseLoading: false,
  });
};


const fetchReportStart = (state, action) => {
  return updateObject(state, {
    fetchReportError: false,
    fetchReportLoading: action.data,
  });
};

const fetchReportSuccess = (state, action) => {
  return updateObject(state, {
    fetchReportSuccess: action.data,
    fetchReportLoading: false,
  });
};

const fetchReportFail = (state, action) => {
  return updateObject(state, {
    fetchReportError: action.error,
    fetchReportLoading: false,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_QUESTION_START:
      return setQuestionStart(state, action);
    case actionTypes.SET_QUESTION_SUCCESS:
      return setQuestionSuccess(state, action);
    case actionTypes.SET_QUESTION_FAIL:
      return setQuestionFail(state, action);

    case actionTypes.SET_USER_RESPONSE_START:
      return setUserResponseStart(state, action);
    case actionTypes.SET_USER_RESPONSE_SUCCESS:
      return setUserResponseSuccess(state, action);
    case actionTypes.SET_USER_RESPONSE_FAIL:
      return setUserResponseFail(state, action);

    case actionTypes.SET_FINISH_RESPONSE_START:
      return setFinishResponseStart(state, action);
    case actionTypes.SET_FINISH_RESPONSE_SUCCESS:
      return setFinishResponseSuccess(state, action);
    case actionTypes.SET_FINISH_RESPONSE_FAIL:
      return setFinishResponseFail(state, action);

      case actionTypes.FETCH_REPORT_START:
      return fetchReportStart(state, action);
    case actionTypes.FETCH_REPORT_SUCCESS:
      return fetchReportSuccess(state, action);
    case actionTypes.FETCH_REPORT_FAIL:
      return fetchReportFail(state, action);
    default:
      return state;
  }
};

export default reducer;

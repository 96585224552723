import React from 'react'
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { toTitleCase
 } from '../../../utils/StringUtils';
const UserLanguageComp = (props) => {
    const options = {
        chart: {
            type: 'bar',
        },
        title: {
            text: props.title
        },
        
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: props.data.categories.map(category => toTitleCase(category)),
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,

                }
            },
            series: {
                color: '#452872'
            }
        },
        legend: {
            enabled : false,
        },
        credits: {
            enabled: false
        },
        series: [{
            data: props.data.data,
            events: {
                legendItemClick: function(e) {
                    e.preventDefault()
                }
              },
              name: `${props.title}`
        }],
      };
    return (
        <div>
        <PieChart highcharts={Highcharts} options={options} />
      </div>
    )
}

export default UserLanguageComp

import React, { Component } from "react";
import { Button, Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  FundViewOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import logo from "../../../assets/logo.png";
import "./sidebar.less";
const { Sider } = Layout;

class Sidebar extends Component {
  state = {
    current: "",
  };

  componentWillMount() {
    
    if (this.props.location.pathname.includes("view-all-jobs")) {
      this.setState({ current: "view-all-jobs"});
    } else if (this.props.location.pathname.includes("results")) {
      this.setState({ current: "5"});
    } else if (this.props.location.pathname.includes("manual")) {
      this.setState({ current: "7"});
    } else if (this.props.location.pathname.includes("pending-tasks")) {
      this.setState({ current: "pending-tasks"});
    } else if (this.props.location.pathname.includes("/")) {
      this.setState({ current: "1"});
    }
  }

  componentWillReceiveProps(nextProp) {
    
    if (nextProp.location.pathname.includes("view-all-jobs")) {
      this.setState({ current: "view-all-jobs"});
    } else if (nextProp.location.pathname.includes("results")) {
      this.setState({ current: "5"});
    } else if (nextProp.location.pathname.includes("manual")) {
      this.setState({ current: "7"});
    } else if (nextProp.location.pathname.includes("pending-tasks")) {
      this.setState({ current: "pending-tasks"});
    } else if (nextProp.location.pathname.includes("/")) {
      this.setState({ current: "1"});
    }
  }

  render() {
    
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        style={{ backgroundColor: "#0f276b" }}
      >
        <Button
          type="link"
          onClick={() => this.props.onToggle()}
          style={{ marginBottom: 2 }}
          id="menu-action"
        >
          {this.props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <Button type="link" href="/logout" id="logout-action">
          {<LogoutOutlined />}
        </Button>
        <div className="logo">
          <img src={logo} alt="logo" height="auto" width={200} />
        </div>
        <Menu
          theme="dark"
          mode="vertical"
          defaultSelectedKeys={this.state.current}
          selectedKeys={this.state.current}
          style={{ backgroundColor: "#0f276b" }}
        >
          <Menu.Item key="view-all-jobs" icon={<FundViewOutlined />}>
            <Link to="/view-all-jobs" aria-label="view all jobs">
              View All Jobs
            </Link>
          </Menu.Item>
          <Menu.Item key="pending-tasks" icon={<FieldTimeOutlined />}>
            <Link to="/pending-tasks" aria-label="pending-task">
              Pending Tasks
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  signUpLoading: false,
  signUpSuccess: null,
  signUpError: false,

  signInSuccess: null,
  signInError: null,
  signInLoading: false,
  userId: sessionStorage.getItem("userId"),

  resetRequestLoading: false,
  resetRequestSuccess: null,
  resetRequestError: false,

  reserPasswordLoading: false,
  reserPasswordSuccess: null,
  reserPasswordError: false,
};


const signUpStart = (state, action) => {
  return updateObject(state, { signUpError: false, signUpLoading: true });
};

const signUpSuccess = (state, action) => {
  return updateObject(state, { signUpSuccess: action.data });
};

const signUpFail = (state, action) => {
  return updateObject(state, { signUpError: action.error });
};


const signInStart = (state, action) => {
  return updateObject(state, { signInError: false, signInLoading: true });
};

const signInSuccess = (state, action) => {
  return updateObject(state, { signInSuccess: action.data.user_id });
};

const signInFail = (state, action) => {
  return updateObject(state, { signInError: action.error });
};

const signOut = (state, action) => {
  return updateObject(state, { signInSuccess: null, signInLoading: false });
};

const resetRequestStart = (state, action) => {
  return updateObject(state, {
    resetRequestError: false,
    resetRequestLoading: true,
  });
};

const resetRequestSuccess = (state, action) => {
  return updateObject(state, { resetRequestSuccess: action.data });
};

const resetRequestFail = (state, action) => {
  return updateObject(state, { resetRequestError: action.error });
};


const reserPasswordStart = (state, action) => {
  return updateObject(state, {
    reserPasswordError: false,
    reserPasswordLoading: true,
  });
};

const reserPasswordSuccess = (state, action) => {
  return updateObject(state, { reserPasswordSuccess: action.data });
};

const reserPasswordFail = (state, action) => {
  return updateObject(state, { reserPasswordError: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_START:
      return signUpStart(state, action);
    case actionTypes.SIGN_UP_SUCCESS:
      return signUpSuccess(state, action);
    case actionTypes.SIGN_UP_FAIL:
      return signUpFail(state, action);

    case actionTypes.SIGN_IN_START:
      return signInStart(state, action);
    case actionTypes.SIGN_IN_SUCCESS:
      return signInSuccess(state, action);
    case actionTypes.SIGN_IN_FAIL:
      return signInFail(state, action);
    case actionTypes.SIGN_OUT:
      return signOut(state, action);

    case actionTypes.RESET_REQUEST_START:
      return resetRequestStart(state, action);
    case actionTypes.RESET_REQUEST_SUCCESS:
      return resetRequestSuccess(state, action);
    case actionTypes.RESET_REQUEST_FAIL:
      return resetRequestFail(state, action);

    case actionTypes.RESET_PASSWORD_START:
      return reserPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return reserPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return reserPasswordFail(state, action);

    default:
      return state;
  }
};

export default reducer;

import * as actionTypes from "./actionTypes";
import axios from "axios";
import config from "../../config";

/**
 *
 * {setQuestionStart} listing process starts
 */
export const setQuestionStart = (data) => {
  return {
    type: actionTypes.SET_QUESTION_START,
    data: data,
  };
};
/**
 *
 * {setQuestionSuccess} list shows up succesfully
 */
export const setQuestionSuccess = (data) => {
  return {
    type: actionTypes.SET_QUESTION_SUCCESS,
    data: data,
  };
};
/**
 *
 * {setQuestionFail} listing fails completes fails
 */
export const setQuestionFail = (error) => {
  return {
    type: actionTypes.SET_QUESTION_FAIL,
    error: error,
  };
};
/**
 *
 * {setQuestion} get all jobs function call on load
 */
export const setQuestion = (obj, callback) => {
  return (dispatch) => {
    dispatch(setQuestionStart(true));
    axios
      .post(`${config.adminApi}manualchecks/question`, obj)
      .then((response) => {
        dispatch(setQuestionStart(true));
        dispatch(setQuestionSuccess({ status: true, response: response.data }));
        dispatch(setQuestionStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setQuestionFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(setQuestionStart(false));
      });
  };
};

/**
 *
 * {setUserResponseStart} listing process starts
 */
export const setUserResponseStart = (data) => {
  return {
    type: actionTypes.SET_USER_RESPONSE_START,
    data: data,
  };
};
/**
 *
 * {setUserResponseSuccess} list shows up succesfully
 */
export const setUserResponseSuccess = (data) => {
  return {
    type: actionTypes.SET_USER_RESPONSE_SUCCESS,
    data: data,
  };
};
/**
 *
 * {setUserResponseFail} listing fails completes fails
 */
export const setUserResponseFail = (error) => {
  return {
    type: actionTypes.SET_USER_RESPONSE_FAIL,
    error: error,
  };
};
/**
 *
 * {setUserResponse} get all jobs function call on load
 */
export const setUserResponse = (obj, callback) => {
  return (dispatch) => {
    dispatch(setUserResponseStart(true));
    axios
      .post(`${config.adminApi}manualchecks/userresponse`, obj)
      .then((response) => {
        dispatch(setUserResponseStart(true));
        dispatch(
          setUserResponseSuccess({ status: true, response: response.data })
        );
        dispatch(setUserResponseStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setUserResponseFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(setUserResponseStart(false));
      });
  };
};

/**
 *
 * {setFinishResponseStart} listing process starts
 */
export const setFinishResponseStart = (data) => {
  return {
    type: actionTypes.SET_FINISH_RESPONSE_START,
    data: data,
  };
};
/**
 *
 * {setFinishResponseSuccess} list shows up succesfully
 */
export const setFinishResponseSuccess = (data) => {
  return {
    type: actionTypes.SET_FINISH_RESPONSE_SUCCESS,
    data: data,
  };
};
/**
 *
 * {setFinishResponseFail} listing fails completes fails
 */
export const setFinishResponseFail = (error) => {
  return {
    type: actionTypes.SET_FINISH_RESPONSE_FAIL,
    error: error,
  };
};
/**
 *
 * {setFinishResponse} get all jobs function call on load
 */
export const setFinishResponse = (obj, callback) => {
  return (dispatch) => {
    dispatch(setFinishResponseStart(true));
    axios
      .post(`${config.adminApi}manualchecks/finishjob`, obj)
      .then((response) => {
        dispatch(setFinishResponseStart(true));
        dispatch(
          setFinishResponseSuccess({ status: true, response: response.data })
        );
        dispatch(setFinishResponseStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setFinishResponseFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(setFinishResponseStart(false));
      });
  };
};



/**
 *
 * {fetchReportStart} listing process starts
 */
 export const fetchReportStart = (data) => {
  return {
    type: actionTypes.FETCH_REPORT_START,
    data: data,
  };
};
/**
 *
 * {fetchReportSuccess} list shows up succesfully
 */
export const fetchReportSuccess = (data) => {
  return {
    type: actionTypes.FETCH_REPORT_SUCCESS,
    data: data,
  };
};
/**
 *
 * {fetchReportFail} listing fails completes fails
 */
export const fetchReportFail = (error) => {
  return {
    type: actionTypes.FETCH_REPORT_FAIL,
    error: error,
  };
};
/**
 *
 * {fetchReport} get all jobs function call on load
 */
export const fetchReport = (obj, callback) => {
  return (dispatch) => {
    dispatch(fetchReportStart(true));
    axios
      .post(`${config.adminApi}fetchreport`, obj)
      .then((response) => {
        dispatch(fetchReportStart(true));
        dispatch(
          fetchReportSuccess({ status: true, response: response.data })
        );
        dispatch(fetchReportStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchReportFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(fetchReportStart(false));
      });
  };
};

import React, { useState, useEffect } from "react";

import { Table, PageHeader, Tag } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { CSVDownloader } from "react-papaparse";

const ReportingResults = (props) => {
  const [data, setdata] = useState([]);
  const [runId, setRunID] = useState([]);

  //get aware detail
  useEffect(() => {
    let runId = props.location.search.replace("?run=", "");
    setRunID(runId);
    let obj = { token: sessionStorage.getItem("token") };
    props.getAwareReports(obj, (res) => {
      if (res && !res.status) {
      } else {
        let result = res.response.aware_report;
        let repRes = result.filter((rep) => rep.run_id === runId);
        setdata(repRes[0].automated_checks_report);
      }
    });
  }, []);

  const columns = [
    {
      title: "Analysis On",
      dataIndex: "Analysis On",
      key: "Analysis On",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Requirement",
      dataIndex: "Requirement",
      key: "Requirement",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (Status) => (
        <p>
          {Status === "failed" ? (
            <Tag color="volcano">{Status}</Tag>
          ) : (
            <Tag color="green">{Status}</Tag>
          )}
        </p>
      ),
    },
    {
      title: "Error",
      key: "Error",
      dataIndex: "Error",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "How to Repair",
      key: "How to Repair",
      dataIndex: "How to Repair",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Repair Example",
      key: "Repair Example",
      dataIndex: "Repair Example",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Disabilities Affected",
      key: "Disabilities Affected",
      dataIndex: "Disabilities Affected",
      render: (text) => (
        <p>
          {text}
        </p>
      ),
    },
    {
      title: "User Impact",
      key: "User Impact",
      dataIndex: "User Impact",
      render: (text) => <p>{text}</p>,
    },
  ];
  return (
    <div className="card-container">
      <div className="head-butt-div">
        <PageHeader
          className="site-page-header"
          onBack={() =>
            props.history.push({
              pathname: "/report",
              search: `?token=${sessionStorage.getItem("token")}`,
            })
          }
          title="Report"
        />
        <CSVDownloader
          data={data}
          type="button"
          filename={`AWARE_REPORT_${runId}`}
          bom={true}
          style={{
            width: 150,
            height: 40,
            backgroundColor: "#a42083",
            color: "#fff",
            fontSize: 14,
          }}
        >
          Download
        </CSVDownloader>
      </div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.analyticsReducer.getPluginStatsLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAwareReports: (results, res) =>
      dispatch(actions.getAwareReports(results, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportingResults)
);

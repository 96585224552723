/*************************************************************AUTH ACTION TYPES *****************************************************************/
/**
 * Action types for sign up
 */
export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";
/**
 * Action types for sign in/ sign out
 */
export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const SIGN_OUT = "SIGN_OUT";
/**
 * Action types for reset request
 */
export const RESET_REQUEST_START = "RESET_REQUEST_START";
export const RESET_REQUEST_SUCCESS = "RESET_REQUEST_SUCCESS";
export const RESET_REQUEST_FAIL = "RESET_REQUEST_FAIL";
/**
 * Action types for reset password
 */
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

/*************************************************************JOBS ACTION TYPES *****************************************************************/
/**
 * Action types for all jobs listing
 */
export const GET_ALL_JOBS_START = "GET_ALL_JOBS_START";
export const GET_ALL_JOBS_SUCCESS = "GET_ALL_JOBS_SUCCESS";
export const GET_ALL_JOBS_FAIL = "GET_ALL_JOBS_FAIL";

/**
 * Action types for all jobs listing
 */
export const GET_REPORT_FLAG_START = "GET_REPORT_FLAG_START";
export const GET_REPORT_FLAG_SUCCESS = "GET_REPORT_FLAG_SUCCESS";
export const GET_REPORT_FLAG_FAIL = "GET_REPORT_FLAG_FAIL";
/**
 * Action types for create job
 */
export const CREATE_JOB_START = "CREATE_JOB_START";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAIL = "CREATE_JOB_FAIL";
/**
 * Action types for create job
 */
export const EXECUTE_JOB_START = "EXECUTE_JOB_START";
export const EXECUTE_JOB_SUCCESS = "EXECUTE_JOB_SUCCESS";
export const EXECUTE_JOB_FAIL = "EXECUTE_JOB_FAIL";
/**
 * Action types for pending jobs listing
 */
export const GET_PENDING_START = "GET_PENDING_START";
export const GET_PENDING_SUCCESS = "GET_PENDING_SUCCESS";
export const GET_PENDING_FAIL = "GET_PENDING_FAIL";
/**
 * Action types for pending jobs listing
 */
export const GET_RESULTS_START = "GET_RESULTS_START";
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS";
export const GET_RESULTS_FAIL = "GET_RESULTS_FAIL";
/**
 * Action types for pending jobs listing
 */
export const GET_PENDING_STATUS_START = "GET_PENDING_STATUS_START";
export const GET_PENDING_STATUS_SUCCESS = "GET_PENDING_STATUS_SUCCESS";
export const GET_PENDING_STATUS_FAIL = "GET_PENDING_STATUS_FAIL";

/**
 * Action types for manual check response
 */
export const SET_MANUAL_QUESTIONS_START = "SET_MANUAL_QUESTIONS_START";
export const SET_MANUAL_QUESTIONS_SUCCESS = "SET_MANUAL_QUESTIONS_SUCCESS";
export const SET_MANUAL_QUESTIONS_FAIL = "SET_MANUAL_QUESTIONS_FAIL";
/**
 * Action types for manual check response
 */
export const SET_QUESTION_START = "SET_QUESTION_START";
export const SET_QUESTION_SUCCESS = "SET_QUESTION_SUCCESS";
export const SET_QUESTION_FAIL = "SET_QUESTION_FAIL";
/**
 * Action types for manual check response
 */
export const SET_USER_RESPONSE_START = "SET_USER_RESPONSE_START";
export const SET_USER_RESPONSE_SUCCESS = "SET_USER_RESPONSE_SUCCESS";
export const SET_USER_RESPONSE_FAIL = "SET_USER_RESPONSE_FAIL";
/**
 * Action types for manual check response
 */
export const SET_FINISH_RESPONSE_START = "SET_FINISH_RESPONSE_START";
export const SET_FINISH_RESPONSE_SUCCESS = "SET_FINISH_RESPONSE_SUCCESS";
export const SET_FINISH_RESPONSE_FAIL = "SET_FINISH_RESPONSE_FAIL";
/**
 * Action types for fetch reports
 */
export const FETCH_REPORT_START = "FETCH_REPORT_START";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAIL = "FETCH_REPORT_FAIL";
/*************************************************************ANALYTICS ACTION TYPES *****************************************************************/
/**
 * Action types for create analytics
 */
export const GET_PLUGIN_STATS_START = "GET_PLUGIN_STATS_START";
export const GET_PLUGIN_STATS_SUCCESS = "GET_PLUGIN_STATS_SUCCESS";
export const GET_PLUGIN_STATS_FAIL = "GET_PLUGIN_STATS_FAIL";

/**
 * Action types for create analytics
 */
export const GET_AWARE_STATS_START = "GET_AWARE_STATS_START";
export const GET_AWARE_STATS_SUCCESS = "GET_AWARE_STATS_SUCCESS";
export const GET_AWARE_STATS_FAIL = "GET_AWARE_STATS_FAIL";

/**
 * Action types for create analytics
 */
export const GET_AWARE_REPORTS_START = "GET_AWARE_REPORTS_START";
export const GET_AWARE_REPORTS_SUCCESS = "GET_AWARE_REPORTS_SUCCESS";
export const GET_AWARE_REPORTS_FAIL = "GET_AWARE_REPORTS_FAIL";

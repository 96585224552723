import React, {useState, useEffect} from 'react'
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";


  
const PieChartDisability = (props) => {
    const [inputData, setinputData] = useState()
    useEffect(() => {
        if(props.data !== undefined && props.data !== null && props.data){
            setinputData(props.data)
        }
    }, [props.data])


    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        credits: false,
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                colors: ["#452872", "#a42083", "#5dbeb7", "#00776a", "#550000"],
            }
        },
        series: [{
            name: 'Report',
            colorByPoint: true,
            data: inputData
        }]
      };
    return (
        <div>
             <PieChart highcharts={Highcharts} options={options} />
        </div>
    )
}

export default PieChartDisability


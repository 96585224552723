import React, { useEffect, useState } from "react";
import SignIn from "../UserManagement/SignIn/SignIn";
import SignUp from "../UserManagement/SignUp/SignUp";
import ResetRequest from "../UserManagement/ResetRequest/ResetRequest";
import ResetPassword from "../UserManagement/ResetPassword/ResetPassword";
import { withRouter } from "react-router-dom";

import "./landingPage.less";

const LandingPage = (props) => {
  /*
    [screen] set the state to show sign up
    */
  const [screen, setScreen] = useState("");
  useEffect(() => {
    /*
        if url has /sign-up slug it'll show sign up page 
        */
    setScreen(props.history.location.pathname);
  }, [props.history.location.pathname]);

  let showScreen = "";
  if (screen === "/sign-up") {
    showScreen = <SignUp />;
  } else if (screen === "/reset-request") {
    showScreen = <ResetRequest />;
  } else if (screen === "/sign-in") {
    showScreen = <SignIn />;
  } else if (screen === "/reset-password") {
    showScreen = <ResetPassword />;
  }
  return (
    <div className="landing-half">
      <div className="first-half">
        {/* Add stats here */}
        <p>stats</p>
      </div>
      <div className="second-half">{showScreen}</div>
    </div>
  );
};

export default withRouter(LandingPage);

import React, { useState, useEffect } from "react";
import PieChartSeverity from "../../CommonComponents/PieChartComp/PieChartSeverity";
import PieChartDisability from "../../CommonComponents/PieChartComp/PieChartDisability";
import { Row, Col, Progress, Select, Button } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import "./accessibilityChecker.less";

const { Option } = Select;
/**Analusis report for checker and plugin */
const AccessibilityChecker = (props) => {
  const [ToSend, setToSend] = useState({});
  const [filter, setFilter] = useState(false);
  const [awareData, setAwareData] = useState([]);

  //get aware stats
  useEffect(() => {
    let obj = { token: sessionStorage.getItem("token") };
    props.getAwareStats(obj, (res) => {
      if (res && !res.status) {
      } else {
        setAwareData(res.response.aware_stats);
      }
    });
  }, []);

  //takes out latest report
  useEffect(() => {
    if (!filter && awareData && awareData.length > 0) {
      let res = awareData && awareData[0].unix_timestamp;
      let obj = awareData && awareData[0];

      awareData.forEach((ad) => {
        if (ad.unix_timestamp > res) {
          res = ad.unix_timestamp;
          obj = ad;
        }
      });
      setToSend(obj);
    }
  });

  //on change of run ids
  const handleChange = (value) => {
    setFilter(true);
    let obj;
    awareData &&
      awareData.forEach((ad) => {
        if (ad.run_id === value) {
          obj = ad;
        }
      });

    setToSend(obj);
  };

  return (
    <div>
      <div className="head-butt-div row-row">
        <h1>Website Accessibility</h1>
        <Button
          type="primary"
          className="primary-butt"
          onClick={() => {
            props.history.push({
              pathname: "/reporting",
              search: `?run=${ToSend.run_id}`,
            });
          }}
        >
          View Report
        </Button>
      </div>
      <div className="row-row">
        {ToSend && ToSend.unix_timestamp && (
          <Select
            value={ToSend && ToSend.unix_timestamp}
            style={{ width: 320 }}
            onSelect={handleChange}
            onChange={handleChange}
          >
            {awareData &&
              awareData.map((ad) => {
                return <Option value={ad.run_id}>{ad.unix_timestamp}</Option>;
              })}
          </Select>
        )}
      </div>
      <Row className="row-row">
        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
          {ToSend && ToSend.violation_by_severity && (
            <PieChartSeverity data={ToSend.violation_by_severity} />
          )}
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
          {ToSend && ToSend.violation_by_severity && (
            <PieChartDisability data={ToSend.violation_by_disability} />
          )}
        </Col>
      </Row>
      <Row className="row-row">
        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Top Issues Found </h2>
          <ul>
            {ToSend &&
              ToSend.top_issues_found &&
              ToSend.top_issues_found.map((str) => <li>{str}</li>)}
          </ul>
        </Col>
        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <span>Overall Verified Compliance</span>

            <div>
              <Progress
                strokeColor={{
                  from: "#df1b1b",
                  to: "#87d068",
                }}
                percent={parseInt(
                  ToSend && (ToSend.overall_automated_compliance / 100) * 30
                )}
                status="active"
              />{" "}
              <span>Automated Testing - (30% WCAG 2.1 Compliance)</span>
              <Progress
                strokeColor={{
                  from: "#df1b1b",
                  to: "#87d068",
                }}
                percent={parseInt(
                  ToSend && ToSend.overall_automated_compliance
                )}
                status="active"
              />{" "}
            </div>
            <div>
              <span>Manual Testing - (70% WCAG 2.1 Compliance)</span>
              <Progress
                strokeColor={{
                  from: "#df1b1b",
                  to: "#87d068",
                }}
                percent={parseInt(ToSend && ToSend.manual_testing_complicance)}
                status="active"
              />
              
              <br />
              <strong>
                <span>
                  <a href="mailto:hello@CityMaaS.io?subject=Manual%20Audit%20-%20WCAG%20Enquiry&body=Hi%2C%20please%20can%20we%20discuss%20a%20manual%20audit%20on%20our%20website%3F%20Best%20Regards">
                    Click here{" "}
                  </a>
                  to get a report on Manual Checks.
                </span>
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.analyticsReducer.getPluginStatsLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPluginStats: (results, res) =>
      dispatch(actions.getPluginStats(results, res)),
    getAwareStats: (results, res) =>
      dispatch(actions.getAwareStats(results, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccessibilityChecker)
);

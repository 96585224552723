import React from "react";
import { Table } from "antd";
import "./guc.less";

const AnalysisTable = (props) => {
  const columns = [
    {
      title: "Categories",
      dataIndex: "element",
      key: "element",
      width: "90%"

    },
    {
      title: "Occurances",
      dataIndex: "occurance",
      key: "occurance",
      width: "10%"
    },

  ];
  return (
    <div>
      <Table
        dataSource={props.dataSource && props.dataSource}
        pagination={false}
        columns={columns}
        title={() => <p className="tb-head">Inaccessible Highlights</p>}
      />
    </div>
  );
};

export default AnalysisTable;

import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Space } from "antd";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { withRouter } from "react-router-dom";
import Notification from "../CommonComponents/Notification/index";

const PendingTasks = (props) => {
  const [pendingJobs, setPendingJobs] = useState([]);
  //gets all pending jobs
  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    const obj = {
      user_id: userId,
      job_id: "lETXHHNZPe",
    };
    props.getPending(obj, (res) => {
      if (res && !res.status) {
      } else {
        setPendingJobs(res.response.result);
      }
    });
  }, []);

  //checks jobs status
  const checkStatus = (record) => {
    const obj = { job_id: record.job_id, sequence: record.sequence };
    props.getPendingStatus(obj, (res) => {
      if (res && !res.status) {
      } else {
        Notification(
          "info",
          res.response &&
            res.response.result &&
            res.response.result[0].manual_checks_status
        );

        let resObj = { ...obj };
        props.getResults(resObj, (response) => {
          if (response && !response.status) {
          } else {
            props.history.push({
              pathname: "/manual-checks",
              state: { job_id: record.job_id, sequence: record.sequence },
            });
          }
        });
      }
    });
  };

  const columns = [
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Automated Checks",
      dataIndex: "automated_checks",
      key: "automated_checks",
      render: (automated_checks) => {
        let color = "";
        if (!automated_checks) {
          color = "volcano";
        } else {
          color = "green";
        }
        return (
          <Tag color={color} key={automated_checks}>
            {automated_checks ? "True" : "False"}
          </Tag>
        );
      },
    },
    {
      title: "Manual Checks",
      dataIndex: "manual_checks",
      key: "manual_checks",
      render: (manual_checks) => {
        let color = "";
        if (!manual_checks) {
          color = "volcano";
        } else {
          color = "green";
        }
        return (
          <Tag color={color} key={manual_checks}>
            {manual_checks ? "True" : "False"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => checkStatus(record)}>
            {record.manual_checks_status === "questionaire generated"
              ? "Start"
              : "Continue"}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="top-div">
        <h1>Pending Jobs</h1>
      </div>
      <Table
        dataSource={pendingJobs}
        columns={columns}
        loading={props.loading}
      />
      ;
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.jobReducer.getPendingLoading,
    error: state.jobReducer.getAllJobsError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPending: (obj, res) => dispatch(actions.getPending(obj, res)),
    getPendingStatus: (obj, res) =>
      dispatch(actions.getPendingStatus(obj, res)),
    getResults: (obj, res) => dispatch(actions.getResults(obj, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingTasks)
);

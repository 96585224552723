import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import "./jobs.less";

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 16,
  },
};

const CreateJob = (props) => {
  /**
   * when data is submitted
   */
  const onFinish = (values) => {
    const userId = sessionStorage.getItem('userId')
    const data = {
        domain: values.domain, 
        user_id: userId,
        description: values.description,
        automated_checks: values.automated_checks ? values.automated_checks  : false,
        manual_checks: values.manual_checks ? values.manual_checks  : false,
        sub_pages: values.sub_pages ? values.sub_pages  : false,
    }
    props.createJob(data, res => {
        if(res && !res.status){

        } else {
            props.history.push('/view-all-jobs')
        }
    })

  };

  //on data being incomplete
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="create-setup">
      <div>
        <h4>Create a new job</h4>
      </div>
      <div className="form-div">
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Domain"
            name="domain"
            rules={[
              {
                required: true,
                message: "Please input your domain",
              },
              {
                message: "Please add your domain as https://www.example.com",
                pattern: new RegExp(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i),

              }
            ]}
          >
            <Input placeholder='https://www.example.com'/>
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input your description",
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item  name="automated_checks" valuePropName="checked">
            <Checkbox> Automated Checks</Checkbox>
          </Form.Item>
          <Form.Item name="manual_checks" valuePropName="checked">
          <Checkbox> Manual Checks </Checkbox>
          </Form.Item>
          <Form.Item  name="sub_pages" valuePropName="checked">
          <Checkbox> Sub Pages</Checkbox>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" onClick={() => {}}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
    return {
      loading: state.jobReducer.createJobLoading,
      error: state.jobReducer.createJobError,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        createJob: (obj, res) => dispatch(actions.createJob(obj, res)),
    };
  };
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateJob));
import React from "react";
import { Form, Input, Button, Radio } from "antd";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import "./signup.css";

const layout = {
  labelCol: {
    span: 4,
    offset: 2
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const SignUp = (props) => {

    /**
     * 
     * when data is submitted 
     */
  const onFinish = (values) => {
    props.onSignUp(values, res => {
        if(!res.status){

        } else {

        }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="form-setup">
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="E-mail"
          name="email_id"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input aria-label="email_id" aria-required="true"/>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password aria-label="Password" aria-required="true"/>
        </Form.Item>
        <Form.Item 
         name="account_role"
         label="Account Role"
         rules={[
            {
              required: true,
              message: "Please input your account role!",
            },
          ]}
         >
          <Radio.Group  aria-labelledby="role-group">
            <Radio value="personal">Personal</Radio>
            <Radio value="business">Business</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" id="action">
            Submit
          </Button>
        </Form.Item>
        <div className="signup-link">
          Have an account? &nbsp; <Link to="/sign-in" aria-label="Sign in">Sign In!</Link>
        </div>
      </Form>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = state => {
    return {
        loading: state.authReducer.signUpLoading,
        error: state.authReducer.signUpError,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSignUp: (credentials, res) => dispatch(actions.signUp(credentials, res)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  getPluginStatsLoading: false,
  getPluginStatsSuccess: null,
  getPluginStatsError: false,

  getAwareStatsLoading: false,
  getAwareStatsSuccess: null,
  getAwareStatsError: false,

  getAwareReportsLoading: false,
  getAwareReportsSuccess: null,
  getAwareReportsError: false,
};

const getPluginStatsStart = (state, action) => {
  return updateObject(state, { getPluginStatsLoading: action.data });
};

const getPluginStatsSuccess = (state, action) => {
  return updateObject(state, { getPluginStatsSuccess: action.data });
};

const getPluginStatsFail = (state, action) => {
  return updateObject(state, { getPluginStatsError: action.error });
};

const getAwareStatsStart = (state, action) => {
  return updateObject(state, { getAwareStatsLoading: action.data });
};

const getAwareStatsSuccess = (state, action) => {
  return updateObject(state, { getAwareStatsSuccess: action.data });
};

const getAwareStatsFail = (state, action) => {
  return updateObject(state, { getAwareStatsError: action.error });
};

const getAwareReportsStart = (state, action) => {
  return updateObject(state, { getAwareReportsLoading: action.data });
};

const getAwareReportsSuccess = (state, action) => {
  return updateObject(state, { getAwareReportsSuccess: action.data });
};

const getAwareReportsFail = (state, action) => {
  return updateObject(state, { getAwareReportsError: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PLUGIN_STATS_START:
      return getPluginStatsStart(state, action);
    case actionTypes.GET_PLUGIN_STATS_SUCCESS:
      return getPluginStatsSuccess(state, action);
    case actionTypes.GET_PLUGIN_STATS_FAIL:
      return getPluginStatsFail(state, action);

    case actionTypes.GET_AWARE_STATS_START:
      return getAwareStatsStart(state, action);
    case actionTypes.GET_AWARE_STATS_SUCCESS:
      return getAwareStatsSuccess(state, action);
    case actionTypes.GET_AWARE_STATS_FAIL:
      return getAwareStatsFail(state, action);

      case actionTypes.GET_AWARE_REPORTS_START:
        return getAwareReportsStart(state, action);
      case actionTypes.GET_AWARE_REPORTS_SUCCESS:
        return getAwareReportsSuccess(state, action);
      case actionTypes.GET_AWARE_REPORTS_FAIL:
        return getAwareReportsFail(state, action);

    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Tabs } from "antd";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import PluginAnalytics from "./PluginAnalytics/PluginAnalytics";
import AccessibilityChecker from "./AccessibilityChecker/AccessibilityChecker";
import "./home.less";

const { TabPane } = Tabs;

const LandingPage = (props) => {
  const [pluginData, setPluginData] = useState([]);
  const [panKey, setPaneKey] = useState("");

  //takes token from url and gets plugin data
  useEffect(() => {
    let serachStr = "";
    if (props.location.search) {
      serachStr = props.location.search;
      let [token, id] = serachStr.split("&");
      token = token.replace("?token=", "");
      //sets token to sessions
      sessionStorage.setItem("token", token);

      let obj = { token: token };
      props.getPluginStats(obj, (res) => {
        if (res && !res.status) {
        } else {
          setPluginData(res.response.plugin_stats);
        }
      });
    }
  }, [panKey]);

  //tabs callback
  function callback(key) {
    console.log(key);
    setPaneKey(key);
  }
  return (
    <>
      <div className="card-container">
        <Tabs onChange={callback}>
          <TabPane tab="Plugin Analytics" key="1">
            <PluginAnalytics pluginData={pluginData} />
          </TabPane>
          <TabPane tab="Accessibility Report" key="2">
            <AccessibilityChecker />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.analyticsReducer.getPluginStatsLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPluginStats: (results, res) =>
      dispatch(actions.getPluginStats(results, res)),
    getAwareStats: (results, res) =>
      dispatch(actions.getAwareStats(results, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);

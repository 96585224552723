import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { withRouter } from "react-router-dom";

import exportFromJSON from "export-from-json";

const Results = (props) => {
  const jobId = props.location.state && props.location.state.job_id;
  const [data, setdata] = useState([]);

  //fetches report of the report
  useEffect(() => {
    props.fetchReport({ job_id: jobId }, (res) => {
      if (res && !res.status) {
      } else {
        let data = res.response.report;
        const fileName = `Report ${jobId}`;
        const exportType = "csv";
        setdata(res.response.report);
        exportFromJSON({ data, fileName, exportType });
      }
    });
  }, []);

  const columns = [
    {
      title: "Analysis On",
      dataIndex: "Analysis On",
      key: "Analysis On",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Check Type",
      dataIndex: "Check_Type",
      key: "Check_Type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Requirement",
      dataIndex: "Requirement",
      key: "Requirement",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Disabilities Affected",
      dataIndex: "Disabilities Affected",
      key: "Disabilities Affected",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: "Error",
      dataIndex: "Error",
      key: "Error",
      render: (text) => <p>{text}</p>,
    },

    {
      title: "How to Repair",
      dataIndex: "How to Repair",
      key: "How to Repair",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: "Level",
      dataIndex: "Level",
      key: "Level",
      render: (text) => <p>{text}</p>,
    },

    {
      title: "Repair Example",
      dataIndex: "Repair Example",
      key: "Repair Example",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },

    {
      title: "User Imapct",
      dataIndex: "User Imapct",
      key: "User Imapct",
    },

    {
      title: "WCAG Reference",
      dataIndex: "WCAG Reference",
      key: "WCAG Reference",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ color: "black" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>Results</h1>
      </div>

      <Table
        dataSource={data}
        columns={columns}
        loading={props.loading}
        scroll={{ x: 1500 }}
        sticky
      />
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.questionsReducer.fetchReportLoading,
    error: state.questionsReducer.getAllJobsError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchReport: (obj, res) => dispatch(actions.fetchReport(obj, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Results)
);

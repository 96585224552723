import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import wordCloud from "highcharts/modules/wordcloud.js";
//import HighchartsReact from "./HighchartsReact.js";
import HighchartsReact from "highcharts-react-official";

wordCloud(Highcharts);

const WordCloudComp = (props) => {
  const filteredData = props.data.filter(item => {
    // Make sure item.name exists and isn't just whitespace
    return item.name && item.name.trim() !== '' ;
  });

  const options = {
    series: [
      {
        colors: ["#452872", "#a42083", "#5dbeb7", "#00776a", "#550000"],
        rotation: {
          from: -60,
          to: 60,
          orientations: 5,
        },
        type: "wordcloud",
        data: filteredData,
      },
    ],
    title: {
      text: "Popular Search Terms",
    },
    tooltip: {
      enabled: false,
    },
    subtitle: {
      text:
        '',
    },
    credits: false
  };
  return (
    <div>
    <HighchartsReact highcharts={Highcharts} options={options} />

    </div>
  );
};

export default WordCloudComp;

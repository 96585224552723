import React, { useEffect, useState } from "react";
import DPieChart from "./3DPieChart";
import logo from "../../assets/cmBlack.png";
import { withRouter } from "react-router";
import AnalysisTable from "./AnalysisTable";
import "./guc.less";

const GuestUserChecks = (props) => {
  const [data, setdata] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [accFlag, setaccFlag] = useState(0);

  //extracts data from url state
  useEffect(async() => {
    let singleArr = [];
    if (props.location && props.location.state) {
      let res = props.location.state.results;
      setdata(props.location.state.results);
      let errCat = res.error_categories;
      let chunk = 20;
      if (res.error_categories.length > 20) {
        for (let i = 0; i <= res.error_categories.length; i += chunk) {
          singleArr.push(errCat.slice(i, i + chunk));
        }
      } else {
        singleArr.push(errCat);
      }

      
      setTableData(singleArr);
      let accessibiltyFlag =
        res.error_categories[res.error_categories.length - 1];
      setaccFlag(accessibiltyFlag.occurance);
      let str = res.description;
      console.log(str.length);
      let result = await str.replaceAll("\n", "<br/>");
      document.getElementById("demo").innerHTML = result;
    }
    setTimeout(() => {
      window.print();
    }, 2000);
  }, []);
  return (
    <div class="book">
      <div class="page">
        <div
          className={
            sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
          }
        >
          {sessionStorage.getItem("imgData") && (
            <img
              src={sessionStorage.getItem("imgData")}
              className="image-logo"
              alt="partner-logo"
            />
          )}
          <img src={logo} className="image-logo" alt="cm-logo" />
        </div>
        <div class="subpage">
          <h1 className="heading">Digital Inclusion Report</h1>{" "}
          <h6 className="domain">{sessionStorage.getItem("domain")}</h6>
          <div className="sub-heading mr-top">
            <p>Accessibility Flags: {accFlag}</p>
            <p>WCAG 2.1 Compliance</p>
          </div>
          <div className="charts-div">
            <div>
          <h6 className="domain">DISABILITIES AFFECTED</h6>

              {data && data.disabilities_affected && (
                <DPieChart
                  data={data.disabilities_affected}
                  title="DISABILITIES AFFECTED"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div
          className={
            sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
          }
        >
          {sessionStorage.getItem("imgData") && (
            <img
              src={sessionStorage.getItem("imgData")}
              className="image-logo"
              alt="partner-logo"
            />
          )}
          <img src={logo} className="image-logo" alt="cm-logo" />
        </div>
        <div class="subpage">
          <div className="charts-div">
            <div>
          <h6 className="domain">SEVERITY OF INACCESSIBILITY</h6>

              {data && data.user_impact && (
                <DPieChart
                  data={data.user_impact}
                  title="SEVERITY OF INACCESSIBILITY"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {tableData && tableData.length > 1 ? (
        tableData.map((tb) =>
          tb.length > 10 ? (
            <div class="page">
              <div
                className={
                  sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
                }
              >
                {sessionStorage.getItem("imgData") && (
                  <img
                    src={sessionStorage.getItem("imgData")}
                    className="image-logo"
                    alt="partner-logo"
                  />
                )}
                <img src={logo} className="image-logo" alt="cm-logo" />
              </div>
              <div class="subpage">
                {/* Analysis */}
                <div className="an-tabe">
                  <AnalysisTable dataSource={tb} />
                </div>
              </div>
            </div>
          ) : (
            <div class="page">
              <div
                className={
                  sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
                }
              >
                {sessionStorage.getItem("imgData") && (
                  <img
                    src={sessionStorage.getItem("imgData")}
                    className="image-logo"
                    alt="partner-logo"
                  />
                )}
                <img src={logo} className="image-logo" alt="cm-logo" />
              </div>
              <div class="subpage">
                {/* Analysis */}
                <div className="an-tabe">
                  <AnalysisTable dataSource={tb} />
                </div>

                <div className="desc-data mr-top">
                  <p className="analysis-hd">Analysis</p>
                  <p id="demo" />
                </div>
              </div>
            </div>
          )
        )
      ) : data &&
        data.error_categories &&
        data.error_categories.length < 10 &&
        data.description &&
        data.description.length < 1075 ? (
        <div class="page">
          <div
            className={
              sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
            }
          >
            {sessionStorage.getItem("imgData") && (
              <img
                src={sessionStorage.getItem("imgData")}
                className="image-logo"
                alt="partner-logo"
              />
            )}
            <img src={logo} className="image-logo" alt="cm-logo" />
          </div>
          <div class="subpage">
            <div className="an-tabe">
              {data && data.error_categories && (
                <AnalysisTable dataSource={data.error_categories} />
              )}
            </div>
            {/* Analysis */}
            {data && data.description && (
              <div className="desc-data">
                <p className="analysis-hd">Analysis</p>
                <p id="demo" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div class="page">
            <div
              className={
                sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
              }
            >
              {sessionStorage.getItem("imgData") && (
                <img
                  src={sessionStorage.getItem("imgData")}
                  className="image-logo"
                  alt="partner-logo"
                />
              )}
              <img src={logo} className="image-logo" alt="cm-logo" />
            </div>
            <div class="subpage">
              <div className="an-tabe">
                {data && data.error_categories && (
                  <AnalysisTable dataSource={data.error_categories} />
                )}
              </div>
            </div>
          </div>
          <div class="page">
            <div
              className={
                sessionStorage.getItem("imgData") ? "sub-heading" : "only-one"
              }
            >
              {sessionStorage.getItem("imgData") && (
                <img
                  src={sessionStorage.getItem("imgData")}
                  className="image-logo"
                  alt="partner-logo"
                />
              )}
              <img src={logo} className="image-logo" alt="cm-logo" />
            </div>
            <div class="subpage">
              {/* Analysis */}
              <div className="desc-data">
                <p className="analysis-hd">Analysis</p>
                <p id="demo" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(GuestUserChecks);

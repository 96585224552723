
import * as actionTypes from './actionTypes';
import axios from 'axios';
import config from '../../config';


/**
 * 
 * {signUpStart} sign up process starts 
 */
export const signUpStart = (data) => {
    return {
        type: actionTypes.SIGN_UP_START,
        data: data
    };
}
/**
 * 
 * {signUpSuccess} sign up completes succesfully
 */
export const signUpSuccess = (data) => {
    return {
        type: actionTypes.SIGN_UP_SUCCESS,
        data: data
    }
}
/**
 * 
 * {signUpFail} sign up completes fails
 */
export const signUpFail = (error) => {
    return {
        type: actionTypes.SIGN_UP_FAIL,
        error: error
    }
}
/**
 * 
 * {signUp} sign up function call on button press
 */
export const signUp = (obj, callback) => {
    return dispatch => {
        dispatch(signUpStart(true));
            axios.post(`${config.adminApi}user/signup`, obj)
                .then(response => {
                    dispatch(signUpStart(true));
                    dispatch(signUpSuccess({ status: true, response: response.data}));
                    dispatch(signUpStart(false));
                    callback({status: true, response: response.data});
                })
                .catch(err => {
                    console.log(err);
                    dispatch(signUpFail(err));
                    callback({ status: false, message: err && err.response && err.response.data.message });
                    dispatch(signUpStart(false));
                })
}
}


/**
 * 
 * {signInStart} sign up process starts 
 */
export const signInStart = (data) => {
    return {
        type: actionTypes.SIGN_IN_START,
        data: data
    };
}
/**
 * 
 * {signInSuccess} sign up completes succesfully
 */
export const signInSuccess = (data) => {
    return {
        type: actionTypes.SIGN_IN_SUCCESS,
        data: data
    }
}
/**
 * 
 * {signInFail} sign up completes fails
 */
export const signInFail = (error) => {
    return {
        type: actionTypes.SIGN_IN_FAIL,
        error: error
    }
}
/**
 * 
 * {signIn} sign up function call on button press
 */
export const signIn = (obj, callback) => {
    return dispatch => {
        dispatch(signInStart(true));
            axios.post(`${config.adminApi}user/login`, obj)
                .then(response => {
                    dispatch(signInStart(true));
                    dispatch(signInSuccess(response.data.data));
                    dispatch(signInStart(false));
                    callback({status: true, response: response.data.data});
                    sessionStorage.setItem('userId', response.data.data.user_id);
                })
                .catch(err => {
                    console.log(err);
                    dispatch(signInFail(err));
                    callback({ status: false, message: err && err.response && err.response.data.message });
                    dispatch(signInStart(false));
                })
}
}

export const signOut = () => {
    
    sessionStorage.removeItem('userId');
    return {
        type: actionTypes.SIGN_OUT,

    }

}

export const signOutUser = () => {
    return dispatch => {
        dispatch(signOut());
        // axios.post(`${config.adminApi}/logout`)
        //     .then(response => {
        //         console.log(response);
        //         sessionStorage.clear();

        //     }).catch(err => {
        //         console.log(err);
        //     })
    }

}






/**
 * 
 * {resetRequestStart} sign up process starts 
 */
export const resetRequestStart = (data) => {
    return {
        type: actionTypes.RESET_REQUEST_START,
        data: data
    };
}
/**
 * 
 * {resetRequestSuccess} sign up completes succesfully
 */
export const resetRequestSuccess = (data) => {
    return {
        type: actionTypes.RESET_REQUEST_SUCCESS,
        data: data
    }
}
/**
 * 
 * {resetRequestFail} sign up completes fails
 */
export const resetRequestFail = (error) => {
    return {
        type: actionTypes.RESET_REQUEST_FAIL,
        error: error
    }
}
/**
 * 
 * {resetRequest} sign up function call on button press
 */
export const resetRequest = (obj, callback) => {
    return dispatch => {
        dispatch(resetRequestStart(true));
            axios.post(`${config.adminApi}user/password/reset/request`, obj)
                .then(response => {
                    dispatch(resetRequestStart(true));
                    dispatch(resetRequestSuccess(response.data.data));
                    dispatch(resetRequestStart(false));
                    callback({status: true, response: response.data.data});
                })
                .catch(err => {
                    console.log(err);
                    dispatch(resetRequestFail(err));
                    callback({ status: false, message: err && err.response && err.response.data.message });
                    dispatch(resetRequestStart(false));
                })
}
}
/**
 * 
 * {resetPasswordStart} sign up process starts 
 */
export const resetPasswordStart = (data) => {
    return {
        type: actionTypes.RESET_PASSWORD_START,
        data: data
    };
}
/**
 * 
 * {resetPasswordSuccess} sign up completes succesfully
 */
export const resetPasswordSuccess = (data) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        data: data
    }
}
/**
 * 
 * {resetPasswordFail} sign up completes fails
 */
export const resetPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error: error
    }
}
/**
 * 
 * {resetPassword} sign up function call on button press
 */
export const resetPassword = (obj, callback) => {
    return dispatch => {
        dispatch(resetPasswordStart(true));
            axios.post(`${config.adminApi}user/password/reset`, obj)
                .then(response => {
                    dispatch(resetPasswordStart(true));
                    dispatch(resetPasswordSuccess(response.data.data));
                    dispatch(resetPasswordStart(false));
                    callback({status: true, response: response.data.data});
                })
                .catch(err => {
                    console.log(err);
                    dispatch(resetPasswordFail(err));
                    callback({ status: false, message: err && err.response && err.response.data.message });
                    dispatch(resetPasswordStart(false));
                })
}
}



import * as actionTypes from "./actionTypes";
import axios from "axios";
import config from "../../config";

/**
 *
 * {getAllJobsStart} listing process starts
 */
export const getAllJobsStart = (data) => {
  return {
    type: actionTypes.GET_ALL_JOBS_START,
    data: data,
  };
};
/**
 *
 * {getAllJobsSuccess} list shows up succesfully
 */
export const getAllJobsSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_JOBS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getAllJobsFail} listing fails completes fails
 */
export const getAllJobsFail = (error) => {
  return {
    type: actionTypes.GET_ALL_JOBS_FAIL,
    error: error,
  };
};
/**
 *
 * {getAllJobs} get all jobs function call on load
 */
export const getAllJobs = (userId, callback) => {
  return (dispatch) => {
    dispatch(getAllJobsStart(true));
    axios
      .get(`${config.adminApi}jobs?user_id=${userId}`)
      .then((response) => {
        dispatch(getAllJobsStart(true));
        dispatch(getAllJobsSuccess({ status: true, response: response.data }));
        dispatch(getAllJobsStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getAllJobsFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getAllJobsStart(false));
      });
  };
};


/**
 *
 * {getReportFlagStart} listing process starts
 */
 export const getReportFlagStart = (data) => {
  return {
    type: actionTypes.GET_REPORT_FLAG_START,
    data: data,
  };
};
/**
 *
 * {getReportFlagSuccess} list shows up succesfully
 */
export const getReportFlagSuccess = (data) => {
  return {
    type: actionTypes.GET_REPORT_FLAG_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getReportFlagFail} listing fails completes fails
 */
export const getReportFlagFail = (error) => {
  return {
    type: actionTypes.GET_REPORT_FLAG_FAIL,
    error: error,
  };
};
/**
 *
 * {getReportFlag} get all jobs function call on load
 */
export const getReportFlag = (userId, callback) => {
  return (dispatch) => {
    dispatch(getReportFlagStart(true));
    axios
      .get(`${config.adminApi}job/report/flag?user_id=${userId}`)
      .then((response) => {
        dispatch(getReportFlagStart(true));
        dispatch(getReportFlagSuccess({ status: true, response: response.data }));
        dispatch(getReportFlagStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getReportFlagFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getReportFlagStart(false));
      });
  };
};
/**
 *
 * {createJobStart} job creation process starts
 */
export const createJobStart = (data) => {
  return {
    type: actionTypes.CREATE_JOB_START,
    data: data,
  };
};
/**
 *
 * {createJobSuccess} job creation completes succesfully
 */
export const createJobSuccess = (data) => {
  return {
    type: actionTypes.CREATE_JOB_SUCCESS,
    data: data,
  };
};
/**
 *
 * {createJobFail} job creation completes fails
 */
export const createJobFail = (error) => {
  return {
    type: actionTypes.CREATE_JOB_FAIL,
    error: error,
  };
};
/**
 *
 * {createJob} job creation function call on button press
 */
export const createJob = (obj, callback) => {
  return (dispatch) => {
    dispatch(createJobStart(true));
    axios
      .post(`${config.adminApi}job/create`, obj)
      .then((response) => {
        dispatch(createJobStart(true));
        dispatch(createJobSuccess({ status: true, response: response.data }));
        dispatch(createJobStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(createJobFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(createJobStart(false));
      });
  };
};

/**
 *
 * {executeJobStart} job execution process starts
 */
export const executeJobStart = (data) => {
  return {
    type: actionTypes.EXECUTE_JOB_START,
    data: data,
  };
};
/**
 *
 * {executeJobSuccess} job execution completes succesfully
 */
export const executeJobSuccess = (data) => {
  return {
    type: actionTypes.EXECUTE_JOB_SUCCESS,
    data: data,
  };
};
/**
 *
 * {executeJobFail} job execution completes fails
 */
export const executeJobFail = (error) => {
  return {
    type: actionTypes.EXECUTE_JOB_FAIL,
    error: error,
  };
};
/**
 *
 * {executeJob} job execution function call on button press
 */
export const executeJob = (obj, callback) => {
  return (dispatch) => {
    dispatch(executeJobStart(true));
    axios
      .post(`${config.adminApi}job/execute`, obj)
      .then((response) => {
        dispatch(executeJobStart(true));
        dispatch(executeJobSuccess({ status: true, response: response.data }));
        dispatch(executeJobStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(executeJobFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(executeJobStart(false));
      });
  };
};

/**
 *
 * {getPendingStart} listing process starts
 */
export const getPendingStart = (data) => {
  return {
    type: actionTypes.GET_PENDING_START,
    data: data,
  };
};
/**
 *
 * {getPendingSuccess} list shows up succesfully
 */
export const getPendingSuccess = (data) => {
  return {
    type: actionTypes.GET_PENDING_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getPendingFail} listing fails completes fails
 */
export const getPendingFail = (error) => {
  return {
    type: actionTypes.GET_PENDING_FAIL,
    error: error,
  };
};
/**
 *
 * {getPending} get all jobs function call on load
 */
export const getPending = (obj, callback) => {
  return (dispatch) => {
    dispatch(getPendingStart(true));
    axios
      .post(`${config.adminApi}manualchecks/pending`, obj)
      .then((response) => {
        dispatch(getPendingStart(true));
        dispatch(getPendingSuccess({ status: true, response: response.data }));
        dispatch(getPendingStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getPendingFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getPendingStart(false));
      });
  };
};

/**
 *
 * {getPendingStatusStart} listing process starts
 */
export const getPendingStatusStart = (data) => {
  return {
    type: actionTypes.GET_PENDING_STATUS_START,
    data: data,
  };
};
/**
 *
 * {getPendingStatusSuccess} list shows up succesfully
 */
export const getPendingStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_PENDING_STATUS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getPendingStatusFail} listing fails completes fails
 */
export const getPendingStatusFail = (error) => {
  return {
    type: actionTypes.GET_PENDING_STATUS_FAIL,
    error: error,
  };
};
/**
 *
 * {getPendingStatus} get all jobs function call on load
 */
export const getPendingStatus = (obj, callback) => {
  return (dispatch) => {
    dispatch(getPendingStatusStart(true));
    axios
      .post(`${config.adminApi}manualchecks/status`, obj)
      .then((response) => {
        dispatch(getPendingStatusStart(true));
        dispatch(
          getPendingStatusSuccess({ status: true, response: response.data })
        );
        dispatch(getPendingStatusStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getPendingStatusFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getPendingStatusStart(false));
      });
  };
};

/**
 *
 * {getResultsStart} listing process starts
 */
export const getResultsStart = (data) => {
  return {
    type: actionTypes.GET_RESULTS_START,
    data: data,
  };
};
/**
 *
 * {getResultsSuccess} list shows up succesfully
 */
export const getResultsSuccess = (data) => {
  return {
    type: actionTypes.GET_RESULTS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getResultsFail} listing fails completes fails
 */
export const getResultsFail = (error) => {
  return {
    type: actionTypes.GET_RESULTS_FAIL,
    error: error,
  };
};
/**
 *
 * {getResults} get all jobs function call on load
 */
export const getResults = (obj, callback) => {
  return (dispatch) => {
    dispatch(getResultsStart(true));
    axios
      .post(`${config.adminApi}manualchecks/question`, obj)
      .then((response) => {
        dispatch(getResultsStart(true));
        dispatch(getResultsSuccess({ status: true, response: response.data }));
        dispatch(getResultsStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getResultsFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getResultsStart(false));
      });
  };
};


/**
 *
 * {setManualQuestionsStart} listing process starts
 */
 export const setManualQuestionsStart = (data) => {
  return {
    type: actionTypes.SET_MANUAL_QUESTIONS_START,
    data: data,
  };
};
/**
 *
 * {setManualQuestionsSuccess} list shows up succesfully
 */
export const setManualQuestionsSuccess = (data) => {
  return {
    type: actionTypes.SET_MANUAL_QUESTIONS_SUCCESS,
    data: data,
  };
};
/**
 *
 * {setManualQuestionsFail} listing fails completes fails
 */
export const setManualQuestionsFail = (error) => {
  return {
    type: actionTypes.SET_MANUAL_QUESTIONS_FAIL,
    error: error,
  };
};
/**
 *
 * {setManualQuestions} get all jobs function call on load
 */
export const setManualQuestions = (obj, callback) => {
  return (dispatch) => {
    dispatch(setManualQuestionsStart(true));
    axios
      .post(`${config.adminApi}manualchecks/response`, obj)
      .then((response) => {
        dispatch(setManualQuestionsStart(true));
        dispatch(
          setManualQuestionsSuccess({ status: true, response: response.data })
        );
        dispatch(setManualQuestionsStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setManualQuestionsFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(setManualQuestionsStart(false));
      });
  };
};

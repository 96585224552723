import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Space } from "antd";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { withRouter } from "react-router-dom";
import Notification from "../CommonComponents/Notification/index";
import "./jobs.less";

const JobsListing = (props) => {
  const [allJobs, setAllJobs] = useState([]);

  //get all jobs
  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    let jobs = [];
    let statuses = [];
    let mixer = [];

    props.getAllJobs(userId, (res) => {
      if (res && !res.status) {
      } else {
        jobs = res.response.jobs;
      }
    });

    props.getReportFlag(userId, (res) => {
      if (res && !res.status) {
      } else {
        statuses = res.response.job_status;
        jobs.forEach((job) => {
          statuses.forEach((stat) => {
            if (job.job_id === stat.job_id) {
              let obj = job;
              obj.status = stat.status;
              console.log("obj");
              mixer.push(obj);
            }
          });
        });
        setAllJobs(mixer);
      }
    });
  }, []);

  //on excuting a job
  const onExecute = (record) => {
    const data = {
      job_id: record.job_id,
      user_id: record.user_id,
    };
    props.executeJob(data, (res) => {
      if (res && !res.status) {
      } else {
        if (res && res.response && res.response.message) {
          props.history.push({ pathname: "/view-all-jobs" });
          Notification('info', res.response.messagee);
        } else {
          props.history.push("/view-all-jobs");
        }
      }
    });
  };

  // on viewing result
  const OnView = (record) => {
    props.history.push({ pathname: "/results", state: { job_id: record.job_id } });
  }

  const columns = [
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Automated Checks",
      dataIndex: "automated_checks",
      key: "automated_checks",
      render: (automated_checks) => {
        let color = "";
        if (!automated_checks) {
          color = "volcano";
        } else {
          color = "green";
        }
        return (
          <Tag color={color} key={automated_checks}>
            {automated_checks ? "True" : "False"}
          </Tag>
        );
      },
    },
    {
      title: "Manual Checks",
      dataIndex: "manual_checks",
      key: "manual_checks",
      render: (manual_checks) => {
        let color = "";
        if (!manual_checks) {
          color = "volcano";
        } else {
          color = "green";
        }
        return (
          <Tag color={color} key={manual_checks}>
            {manual_checks ? "True" : "False"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => onExecute(record)}>
            Execute
          </Button>
          {record.status !== 'pending execution'  && <Button type="link" onClick={() => OnView(record)}>View</Button>}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="top-div">
        <h1>All Jobs</h1>
        <Button type="link" href="/create-job">
          Create
        </Button>
      </div>
      <Table dataSource={allJobs} columns={columns} loading={allJobs && allJobs.length < 1} />;
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.jobReducer.getAllJobsLoading,
    error: state.jobReducer.getAllJobsError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllJobs: (userId, res) => dispatch(actions.getAllJobs(userId, res)),
    getReportFlag: (userId, res) =>
      dispatch(actions.getReportFlag(userId, res)),
    executeJob: (data, res) => dispatch(actions.executeJob(data, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobsListing)
);

import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Upload, Select } from "antd";
import Notification from "../CommonComponents/Notification/index";
import config from "../../config";
import axios from "axios";
import "./bd.less";
import { withRouter } from "react-router";
import { UploadOutlined } from "@ant-design/icons";

//bd tool for business team
const BizDevBD = (props) => {
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    sessionStorage.removeItem("domain");
    sessionStorage.removeItem("imgData");
    let domain = "";
    if (props.location && props.location.search) {
      domain = props.location.search;
      domain = domain.replace("?domain=", "");
    }
    form.setFieldsValue({
      domain: domain,
    });
  }, []);

  //makes an api call
  const onFinish = async (values) => {
    var data = new FormData();
    data.append("domain", values.domain);
    data.append("country", values.country);
    data.append("source", "selenium");

    sessionStorage.setItem("domain", values.domain);
    setloading(true);

    let options = {
      method: "post",
      url: `${config.adminApi}job/execute/businessdev`,
      data: data,
    };
    axios(options)
      .then(function (response) {
        props.history.push({
          pathname: "/aware-report",
          state: { results: response.data.results },
        });
      })
      .catch(function (error) {
        console.log(error);
        setloading(false);

        Notification("error", "Something went wrong");
      });
  };

  //default ant d function to upload image
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  //stores data to session
  const onChangeLogo = async (file) => {
    let imgData = await getBase64(file.file.originFileObj);
    sessionStorage.setItem("imgData", imgData);
  };

  //converts to base 64
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <div className="bg-all">
      <Card className="bd-card">
        <Form
          name="auto-checks"
          onFinish={onFinish}
          autoComplete="on"
          form={form}
        >
          {" "}
          <Form.Item
            name="domain"
            rules={[
              {
                message: "Please add your domain as https://www.example.com",
                pattern: new RegExp(
                  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
                ),
              },
              {
                required: true,
                message: "Please input yourdomain!",
              },
            ]}
          >
            <Input placeholder="Domain" />
          </Form.Item>
          <Form.Item name="country" label="Select a country">
            <Select placeholder="Select a country">
              <Select.Option value="Australia">Australia</Select.Option>
              <Select.Option value="Canada">Canada</Select.Option>
              <Select.Option value="China">China</Select.Option>
              <Select.Option value="European Union">
                European Union
              </Select.Option>
              <Select.Option value="Hong Kong">Hong Kong</Select.Option>
              <Select.Option value="India">India</Select.Option>
              <Select.Option value="Israel">Israel</Select.Option>
              <Select.Option value="Japan">Japan</Select.Option>
              <Select.Option value="New Zealand">New Zealand</Select.Option>
              <Select.Option value="Norway">Norway</Select.Option>
              <Select.Option value="Republic of Korea">
                Republic of Korea
              </Select.Option>
              <Select.Option value="Switzerland">Switzerland</Select.Option>
              <Select.Option value="Taiwan">Taiwan</Select.Option>
              <Select.Option value="United Kingdom">
                United Kingdom
              </Select.Option>
              <Select.Option value="United States of America">
                United States of America
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="file" label="Logo">
            <Upload
              name="file"
              listType="picture"
              customRequest={dummyRequest}
              onChange={onChangeLogo}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default withRouter(BizDevBD);

import React from "react";
import { Chart } from "react-google-charts";
import "./guc.less";
const DPieChart = (props) => {
  return (
    <Chart
      width={"750px"}
      height={"650px"}
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={[["Disability", "Speakers"], ...props.data]}
      options={{
        // title: props.title,
        // Just add this option
        is3D: true,
        colors: [
          "#b88aff",
          "#00ffe3",
          "#ff80df",
          "#ffc15e",
          "#82bfff",
          "#ff667d",
          "#ffff80",
          "#ff8d80",
          "#86ff80",
          "#00c3ff",
          "#8093ff",
          "#80ffce",
          "#ff4545"
        ],
        legend: {
          position: "labeled",
          textStyle: {
            color: "black",
            fontSize: 10,
          },
        },
        chartArea: {
          width: "80%"
        },
        tooltip: {
          text: "value",
          textStyle: {
            color: "black",
          },
        },
        pieSliceText: "none",
      }}
      // rootProps={{ "data-testid": "2" }}
    />
  );
};

export default DPieChart;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { withRouter } from "react-router-dom";
import { Pagination, Spin, Radio, Skeleton, Button } from "antd";
import Notification from "../CommonComponents/Notification/index";

import "./ManualChecksQuestions.less";

const ManualChecksQuestions = (props) => {
  const jobId = props.location.state && props.location.state.job_id;
  const [sequence, setSequence] = useState();
  const [question, setQuestion] = useState({});
  const [pageLimit, setpageLimit] = useState(0);
  const [userResvalue, setUserResvalue] = useState([]);
  const [leftFlag, setLeftFlag] = useState(false);

  //gets question one by one
  useEffect(() => {
    // setUserResvalue([]);
    if (props.location.state && props.location.state.job_id) {
      setSequence(props.location.state.sequence);
      sessionStorage.setItem(
        "jobId",
        JSON.stringify(props.location.state && props.location.state)
      );

      props.setQuestion(
        {
          job_id: props.location.state.job_id,
          sequence: props.location.state.sequence,
        },
        (res) => {
          if (res && !res.status) {
          } else {
            let result = res.response.next_question[0];
            setpageLimit(result.last_check_sequence);
            setQuestion(result);
            setUserResvalue(res.response.next_question[0].user_response_code);
          }
        }
      );
    }
  }, []);

  //on changing question
  const onPageChange = (num) => {
    let userResponse = {};

    if (userResvalue && userResvalue) {
      userResponse = {
        user_response_code: userResvalue,
      };
      props.setUserResponse(
        {
          job_id: jobId,
          sequence: sequence,
          ...userResponse,
        },
        (resp) => {
          if (resp && !resp.status) {
          } else {
            props.setQuestion({ job_id: jobId, sequence: num }, (res) => {
              if (res && !res.status) {
              } else {
                setpageLimit(res.response.next_question[0].last_check_sequence);
                setQuestion(res.response.next_question[0]);
                setSequence(num);
                setUserResvalue(
                  res.response.next_question[0].user_response_code
                );
              }
            });
          }
        }
      );
    } else {
      props.setQuestion({ job_id: jobId, sequence: num }, (res) => {
        if (res && !res.status) {
        } else {
          setpageLimit(res.response.next_question[0].last_check_sequence);
          setQuestion(res.response.next_question[0]);
          setSequence(num);
          setUserResvalue(res.response.next_question[0].user_response_code);
        }
      });
    }
  };

  //on getting response
  const onUserResChange = (e) => {
    let val = e.target.value;
    setUserResvalue(val);
  };

  //on submitting final question
  const handleFinalResponse = (flag) => {
    props.setFinishResponse({ job_id: jobId, force: flag }, (res) => {
      if (res && !res.status) {
      } else if (res && res.response.user_warning === "Unanswered Questions") {
        setLeftFlag(true);
        Notification("info", "Unanswered Questions");
      } else {
        props.history.push({ pathname: "/results", state: { job_id: jobId } });

        Notification("success", "All done");
      }
    });
  };
  return (
    <div>
      <div className="body-manual-check">
        {props.setQuestionLoading ? (
          <div className="spinner">
            <Skeleton active paragraph={{ rows: 6 }} />
          </div>
        ) : (
          <>
            <div className="text">
              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Question: </strong>
                  </span>
                  {question.question_content}
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Element Name: </strong>
                  </span>
                  {question.element_name}
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Element Source: </strong>
                  </span>
                  <span>{question.element_source}</span>
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Element HTML: </strong>
                  </span>
                  <span>{question.element_html}</span>
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>WCAG Reference: </strong>
                  </span>
                  {question.wcag_reference}
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Level: </strong>
                  </span>
                  {question && question.level && question.level.toString()}
                  {/* {question.level} */}
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Category: </strong>
                  </span>
                  {question.category}
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Severity: </strong>
                  </span>
                  {question.severity}
                </p>
              </div>

              <div className="question-info">
                <p>
                  <span className="key-info">
                    <strong>Disabilities: </strong>
                  </span>
                  {question.disabilities &&
                    question.disabilities.map((d) => <span>{d} </span>)}
                </p>
              </div>
              <div class="preventSelect"></div>
            </div>
            <div>
              <Radio.Group
                onChange={onUserResChange}
                value={userResvalue}
                defaultValue={userResvalue}
              >
                {question &&
                  question.response &&
                  question.response.map((res) => (
                    <Radio value={res.response_code}>
                      {res.response_content}
                    </Radio>
                  ))}
              </Radio.Group>
            </div>
          </>
        )}
      </div>

      <div className="paging">
        {pageLimit === sequence && !leftFlag && (
          <Button type="primary" onClick={() => handleFinalResponse(false)}>
            Submit
          </Button>
        )}

        {leftFlag && (
          <Button
            type="primary"
            onClick={() => {
              handleFinalResponse(true);
            }}
          >
            Force Submit
          </Button>
        )}
        <Pagination
          simple
          defaultCurrent={sequence}
          total={pageLimit}
          pageSize={1}
          current={sequence}
          onChange={onPageChange}
          showSizeChanger={false}
          disabled={props.setQuestionLoading}
        />
      </div>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    setQuestionLoading: state.questionsReducer.setQuestionLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQuestion: (obj, res) => dispatch(actions.setQuestion(obj, res)),
    setUserResponse: (obj, res) => dispatch(actions.setUserResponse(obj, res)),
    setFinishResponse: (obj, res) =>
      dispatch(actions.setFinishResponse(obj, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManualChecksQuestions)
);

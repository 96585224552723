import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  getAllJobsLoading: false,
  getAllJobsSuccess: null,
  getAllJobsError: false,

  getReportFlagLoading: false,
  getReportFlagSuccess: null,
  getReportFlagError: false,

  createJobLoading: false,
  createJobSuccess: null,
  createJobError: false,

  executeJobLoading: false,
  executeJobSuccess: null,
  executeJobError: false,

  getPendingLoading: false,
  getPendingSuccess: null,
  getPendingError: false,

  getPendingStatusLoading: false,
  getPendingStatusSuccess: null,
  getPendingStatusError: false,

  getResultsLoading: false,
  getResultsSuccess: null,
  getResultsError: false,

  setManualQuestionsLoading: false,
  setManualQuestionsSuccess: null,
  setManualQuestionsError: false,
};


const getAllJobsStart = (state, action) => {
  return updateObject(state, {
    getAllJobsError: false,
    getAllJobsLoading: action.data,
  });
};

const getAllJobsSuccess = (state, action) => {
  return updateObject(state, {
    getAllJobsSuccess: action.data,
    getAllJobsLoading: false,
  });
};

const getAllJobsFail = (state, action) => {
  return updateObject(state, {
    getAllJobsError: action.error,
    getAllJobsLoading: false,
  });
};


const getReportFlagStart = (state, action) => {
  return updateObject(state, {
    getReportFlagError: false,
    getReportFlagLoading: action.data,
  });
};

const getReportFlagSuccess = (state, action) => {
  return updateObject(state, {
    getReportFlagSuccess: action.data,
    getReportFlagLoading: false,
  });
};

const getReportFlagFail = (state, action) => {
  return updateObject(state, {
    getReportFlagError: action.error,
    getReportFlagLoading: false,
  });
};


const createJobStart = (state, action) => {
  return updateObject(state, { createJobError: false, createJobLoading: true });
};

const createJobSuccess = (state, action) => {
  return updateObject(state, { createJobSuccess: action.data });
};

const createJobFail = (state, action) => {
  return updateObject(state, { createJobError: action.error });
};


const executeJobStart = (state, action) => {
  return updateObject(state, {
    executeJobError: false,
    executeJobLoading: true,
  });
};

const executeJobSuccess = (state, action) => {
  return updateObject(state, { executeJobSuccess: action.data });
};

const executeJobFail = (state, action) => {
  return updateObject(state, { executeJobError: action.error });
};


const getPendingStart = (state, action) => {
  return updateObject(state, {
    getPendingError: false,
    getPendingLoading: action.data,
  });
};

const getPendingSuccess = (state, action) => {
  return updateObject(state, {
    getPendingSuccess: action.data,
    getPendingLoading: false,
  });
};

const getPendingFail = (state, action) => {
  return updateObject(state, {
    getPendingError: action.error,
    getPendingLoading: false,
  });
};


const getPendingStatusStart = (state, action) => {
  return updateObject(state, {
    getPendingStatusError: false,
    getPendingStatusLoading: action.data,
  });
};

const getPendingStatusSuccess = (state, action) => {
  return updateObject(state, {
    getPendingStatusSuccess: action.data,
    getPendingStatusLoading: false,
  });
};

const getPendingStatusFail = (state, action) => {
  return updateObject(state, {
    getPendingStatusError: action.error,
    getPendingStatusLoading: false,
  });
};


const getResultsStart = (state, action) => {
  return updateObject(state, {
    getResultsError: false,
    getResultsLoading: action.data,
  });
};

const getResultsSuccess = (state, action) => {
  return updateObject(state, {
    getResultsSuccess: action.data,
    getResultsLoading: false,
  });
};

const getResultsFail = (state, action) => {
  return updateObject(state, {
    getResultsError: action.error,
    getResultsLoading: false,
  });
};


const setManualQuestionsStart = (state, action) => {
  return updateObject(state, {
    setManualQuestionsError: false,
    setManualQuestionsLoading: action.data,
  });
};

const setManualQuestionsSuccess = (state, action) => {
  return updateObject(state, {
    setManualQuestionsSuccess: action.data,
    setManualQuestionsLoading: false,
  });
};

const setManualQuestionsFail = (state, action) => {
  return updateObject(state, {
    setManualQuestionsError: action.error,
    setManualQuestionsLoading: false,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_JOBS_START:
      return getAllJobsStart(state, action);
    case actionTypes.GET_ALL_JOBS_SUCCESS:
      return getAllJobsSuccess(state, action);
    case actionTypes.GET_ALL_JOBS_FAIL:
      return getAllJobsFail(state, action);

    case actionTypes.GET_REPORT_FLAG_START:
      return getReportFlagStart(state, action);
    case actionTypes.GET_REPORT_FLAG_SUCCESS:
      return getReportFlagSuccess(state, action);
    case actionTypes.GET_REPORT_FLAG_FAIL:
      return getReportFlagFail(state, action);

    case actionTypes.CREATE_JOB_START:
      return createJobStart(state, action);
    case actionTypes.CREATE_JOB_SUCCESS:
      return createJobSuccess(state, action);
    case actionTypes.CREATE_JOB_FAIL:
      return createJobFail(state, action);

    case actionTypes.EXECUTE_JOB_START:
      return executeJobStart(state, action);
    case actionTypes.EXECUTE_JOB_SUCCESS:
      return executeJobSuccess(state, action);
    case actionTypes.EXECUTE_JOB_FAIL:
      return executeJobFail(state, action);

    case actionTypes.GET_PENDING_START:
      return getPendingStart(state, action);
    case actionTypes.GET_PENDING_SUCCESS:
      return getPendingSuccess(state, action);
    case actionTypes.GET_PENDING_FAIL:
      return getPendingFail(state, action);

    case actionTypes.GET_PENDING_STATUS_START:
      return getPendingStatusStart(state, action);
    case actionTypes.GET_PENDING_STATUS_SUCCESS:
      return getPendingStatusSuccess(state, action);
    case actionTypes.GET_PENDING_STATUS_FAIL:
      return getPendingStatusFail(state, action);

    case actionTypes.GET_RESULTS_START:
      return getResultsStart(state, action);
    case actionTypes.GET_RESULTS_SUCCESS:
      return getResultsSuccess(state, action);
    case actionTypes.GET_RESULTS_FAIL:
      return getResultsFail(state, action);

    case actionTypes.SET_MANUAL_QUESTIONS_START:
      return setManualQuestionsStart(state, action);
    case actionTypes.SET_MANUAL_QUESTIONS_SUCCESS:
      return setManualQuestionsSuccess(state, action);
    case actionTypes.SET_MANUAL_QUESTIONS_FAIL:
      return setManualQuestionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

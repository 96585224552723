import React from 'react'
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";


  
const PieChartSeverity = (props) => {
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                colors: ["#452872", "#a42083", "#5dbeb7", "#00776a", "#550000"],

            }
        },
        credits: false,
        series: [{
            name: 'Report',
            colorByPoint: true,
            data: props.data !== undefined && props.data !== null && props.data,
        }]
    };
    return (
        <div>
             <PieChart highcharts={Highcharts} options={options} />
        </div>
    )
}

export default PieChartSeverity

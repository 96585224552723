import React from "react";
import { Row, Col } from "antd";

import ColumnChart from "../../CommonComponents/ColumnChart/ColumnChart";
import WordCloudComp from "../../CommonComponents/WordCloudComp/WordCloudComp";
import UserLanguageComp from "../../CommonComponents/UserLanguageComp/UserLanguageComp";
import FeaturesComp from "../../CommonComponents/FeaturesComp/FeaturesComp";
import "./PluginAnalytics.less";

//assist me plugin stats
const PluginAnalytics = (props) => {
  return (
    <div>
      <h1>Assist Plugin - Analytics - {props.pluginData && props.pluginData.domain && (props.pluginData.domain)}</h1>
      <Row justify="space-around">
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {props.pluginData && props.pluginData.users_by_disability_groups && (
            <ColumnChart data={props.pluginData.users_by_disability_groups} />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {props.pluginData && props.pluginData.popular_search_terms && (
            <WordCloudComp data={props.pluginData.popular_search_terms} />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {props.pluginData && props.pluginData.users_by_language_used && (
            <UserLanguageComp
              data={props.pluginData.users_by_language_used}
              title="Users by Language"
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="gap"></div>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {props.pluginData &&
            props.pluginData.style_adjustment_features_stats && (
              <FeaturesComp
                data={props.pluginData.style_adjustment_features_stats}
                title="Style Adjustment Features"
              />
            )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {props.pluginData &&
            props.pluginData.content_adjustment_features_stats && (
              <FeaturesComp
                data={props.pluginData.content_adjustment_features_stats}
                title="Content Adjustment Features"
              />
            )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {props.pluginData &&
            props.pluginData.navigation_adjustment_features_stats && (
              <FeaturesComp
                data={props.pluginData.navigation_adjustment_features_stats}
                title="Navigation Adjustment Features"
              />
            )}
        </Col>
      </Row>
    </div>
  );
};

export default PluginAnalytics;

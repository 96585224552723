import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Notification from "../../CommonComponents/Notification/index";
import "./signin.less";

const layout = {
  labelCol: {
    span: 4,
    offset: 2
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const SignIn = (props) => {
  /**
   * on first time sign in user will get redirected from email
   * finds assist key in url
   */
  useEffect(() => {
    let assist_id = "";
    if (props.location && props.location.search) {
      assist_id = props.location.search;
      assist_id = assist_id.replace("?assistkey=", "");
    const credentials = { 
        password: "Password@123",
        email_id: "email@email.com",
        mode: "email", 
        assist_id 
    };

    props.onSignIn(credentials, (res) => {
        if (res && !res.status) {
            Notification('info', res.message);
        } else {
          props.history.push("/");
        }
      });

    }
  }, [props, props.location]);
  /**
   * when data is submitted
   */
  const onFinish = (values) => {
    const credentials = { ...values, mode: "user", assist_id: '' };
    props.onSignIn(credentials, (res) => {
        
      if (res && !res.status) {
          
      } else {
        props.history.push("/");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="form-setup">
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email"
          name="email_id"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input aria-label="email_id" aria-required="true" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password aria-label="Password" aria-required="true" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" id="action">
            Submit
          </Button>
        </Form.Item>
        <div className="signup-link">
          Don't have an account? &nbsp; <Link to="/sign-up" aria-label="sign up">Sign Up!</Link>
        </div>
        <div className="signup-link">
          Forgot Password? &nbsp; <Link to="/reset-request" aria-label="reset">Reset</Link>
        </div>
      </Form>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.signInSuccess,
    error: state.authReducer.signInError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (credentials, res) => dispatch(actions.signIn(credentials, res)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));

import React from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { toTitleCase } from "../../../utils/StringUtils";

const ColumnChart = (props) => {

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Users by Disability Group",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.data.categories.map(category => toTitleCase(category)),
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: ")",
      },
    },
    tooltip: {
      pointFormat: '{point.y:.1f}%'
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        color: "#452872",
      },
    },
    legend: {
      enabled : false,
    },
    series: [
      {
        data: props.data.data,
        events: {
          legendItemClick: function(e) {
              e.preventDefault()
          }
        },
        name: `Disability Groups`
      },
    ],
    credits: false,
  };
  return (
    <div>
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ColumnChart;

import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "./components/Layout/Sidebar/Sidebar";
//import ViewTest from "./components/ViewTest/ViewTest";
import JobsListing from "./components/Jobs/JobsListing";
import CreateJob from "./components/Jobs/CreateJob";
import { connect } from "react-redux";
import PendingTasks from "./components/PendingTasks/PendingTasks";
import ManualChecksQuestions from "./components/ManualChecksQuestions/ManualChecksQuestions";
import Results from "./components/Results/Results";
import "./App.less";

const { Content } = Layout;
class App extends Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    let route = "";
    if (this.props.isAuthenticated || this.props.isAuthData) {
      route = (
        <Switch>
          {/* <Route
            exact
            path="/"
            render={({ match }) => <ViewTest match={match} />}
          /> */}
          <Route
            exact
            path="/view-all-jobs"
            render={({ match }) => <JobsListing match={match} />}
          />
          <Route
            exact
            path="/create-job"
            render={({ match }) => <CreateJob match={match} />}
          />
          <Route
            exact
            path="/manual-checks"
            render={({ match }) => <ManualChecksQuestions match={match} />}
          />
          <Route
            exact
            path="/pending-tasks"
            render={({ match }) => <PendingTasks match={match} />}
          />
            <Route
            exact
            path="/results"
            render={({ match }) => <Results match={match} />}
          />
        </Switch>
      );
    }
    return (
      <Layout className="App-header">
        <Sidebar
          collapsible
          collapsed={this.state.collapsed}
          onToggle={this.toggle}
        />
        <Layout className="site-layout">
          <Content
            className="site-layout-background"
            style={{
              margin: "34px 26px",
              padding: 24,
              minHeight: 280,
            }}
          >
            {route}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});

export default withRouter(connect(mapStateToProps, null)(App));
